/***
 *
 *   Classroom Page
 *   view a classroom
 *
 **********/

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import {
  OrganizationCard,
  Header,
  Animate,
  OrganizationTable,
  useAPI,
  Button,
  ViewContext,
  usePermissions,
  Event,
} from "components/lib";

export function Classroom(props) {
  const params = useParams();
  const viewContext = useContext(ViewContext);
  const permissions = usePermissions();

  const [students, setStudent] = useState([]);
  const [teachers, setTeachers] = useState([]);

  //get classroom data
  const classroom =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id
    ) || [];

  // get teachers
  const teachersData =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id +
        "/teachers"
    ) || [];

  console.log(teachersData);

  //get students
  const studentsData =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id +
        "/students"
    ) || [];

  useEffect(() => {
    setStudent(studentsData.data);
    setTeachers(teachersData.data);
  }, [studentsData, teachersData]);

  async function editClassroom() {
    console.log("edit classroom");
  }

  async function addTeacher() {
    console.log("add teacher");
  }

  async function addStudent() {
    console.log("add student");
  }

  function addTeacher() {
    viewContext.modal.show(
      {
        title: "Add Teacher",
        form: {
          email: {
            label: "Email",
            type: "text",
            required: true,
          },
          permission: {
            label: "Permission",
            value: "teacher",
            type: "hidden",
          },
        },
        buttonText: "Send Invite",
        text: "To invite more than one user, seperate the emails with a comma",
        url: "/api/invite",
        method: "POST",
      },
      (form, res) => {
        // add the invited user to the
        if (res && res.length) {
          const state = [...teachers];

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                name: "",
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || "teacher",
                status: "Invited",
                actions: {
                  invite: resendInvite,
                  delete: deleteInvite,
                },
              });
            }
          });

          Event("invited_user");
          setTeachers(state);
        }
      }
    );
  }

  function editUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Update ",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: data.name,
            errorMessage: "Please enter a name",
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
          permission: {
            label: "Permission",
            type: data.permission === "school_admin" ? null : "select",
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "school_admin" &&
                x.value !== "teacher" &&
                x.value !== "student"
            ),
            default: data.permission,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          data.name + " was updated",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete District Admin",
        form: {},
        buttonText: "Delete Admin",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.name + " was deleted",
          "success",
          true
        );
        callback();
      }
    );
  }

  function deleteInvite(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete Invite",
        form: {},
        buttonText: "Delete Invite",
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/invite/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          `${data.email}'s invite was deleted`,
          "success",
          true
        );
        callback();
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      viewContext.notification.show(
        `Invite re-sent to ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      viewContext.handleError(err);
    }
  }

  return (
    <>
      <Header />

      <header className="content-header">
        <Button action={editClassroom} text="Edit" color="brown" small right />

        <h1>
          Classroom: <span>{classroom.data?.name}</span>
        </h1>
      </header>

      <Animate>
        <OrganizationCard loading={classroom.loading} title={"About"}>
          <b>Subject:</b> {classroom.data?.subject}
          <br />
          <b>Periods:</b> {classroom.data?.periods}
        </OrganizationCard>

        <br />
        <Button
          action={addTeacher}
          text="Add Teacher"
          icon="plus"
          outline
          color="blue"
        />
        <OrganizationCard
          loading={teachers?.loading}
          title={"Teachers " + teachers?.data?.length}
        >
          <OrganizationTable
            data={teachers?.data}
            show={["name"]}
            link={{
              url: "/account/organization/teacher/",
              id: "id",
            }}
          />
        </OrganizationCard>

        <br />

        <Button
          action={addStudent}
          text="Add Student"
          icon="plus"
          outline
          color="blue"
        />
        <br />

        <OrganizationCard title={"Students " + students?.data?.length}>
          <OrganizationTable
            search
            loading={students?.loading}
            data={students?.data}
            show={["name"]}
            link={{
              url: "/account/organization/student/",
              id: "id",
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
