/***
 *
 *   SCHOOL Page
 *   view the school data including classrooms
 *   depending on permisisons show all classes (admin) or teacher classes
 *
 **********/

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import {
  OrganizationCard,
  Header,
  useNavigate,
  OrganizationTable,
  useAPI,
  ViewContext,
  AuthContext,
  Button,
  usePermissions,
  Table,
  Event,
} from "components/lib";

export function School(props) {
  const authContext = useContext(AuthContext);

  const account_id = authContext.user.account_id;

  const params = useParams();
  const [classes, setClasses] = useState([{ loading: true }]);
  const viewContext = useContext(ViewContext);
  const data = useAPI("/api/account/users");
  const permissions = usePermissions();
  const navigate = useNavigate();

  const [memberAdmin, setMemberAdmin] = useState([]);

  // get school
  const school = useAPI("/api/account/school/" + params.id);
  const [schoolName, setSchoolName] = useState("");

  useEffect(() => {
    if (school.data) {
      setSchoolName(school.data.name);
    }
  }, [school.data]);

  const classesList = ["name", "subject", "periods", "teacher_name"];

  useEffect(() => {
    if (!school?.loading && school.data?.source_id) {
      axios
        .get("/api/account/school/" + school.data.source_id + "/classes")
        .then((response) => {
          setClasses(response.data);
          console.log("classes " + response.data);
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [school]);

  useEffect(() => {
    // format the user list
    let adminList = [];

    if (data?.data?.users?.length) {
      adminList = data.data.users
        .filter((x) => ["school_admin"].includes(x.permission))
        .map((x) => {
          return {
            id: x.id,
            avatar: x.avatar,
            name: x.name,
            email: x.email,
            date_created: x.date_created,
            permission: x.permission,
            status: x.verified ? "Verified" : "Registered",
          };
        });
    }
    if (data?.data?.invites?.length) {
      data.data.invites
        .filter((x) =>
          ["school_admin", "teacher", "student"].includes(x.permission)
        )
        .forEach((x) => {
          adminList.push({
            id: x.id,
            name: "",
            email: x.email,
            date_created: x.date_sent,
            permission: x.permission || "user",
            status: "Invited",
          });
        });
    }

    setMemberAdmin(adminList);
  }, [data]);

  // attach the per row actions for invites
  if (memberAdmin.length) {
    memberAdmin.forEach((u) => {
      if (u.status === "Invited") {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      }
    });
  }

  async function editSchool() {
    viewContext.modal.show(
      {
        title: "Update School",
        form: {
          account_id: {
            type: "hidden",
            value: account_id,
          },
          id: {
            type: "hidden",
            value: school.data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: school.data.name,
          },
        },
        buttonText: "Save",
        url: "/api/organization/" + school.data.id,
        method: "PATCH",
      },
      (res) => {
        if (res && res.data && res.data.data) {
          setSchoolName(res.data.data.name);
          viewContext.notification.show(
            res.data.data.name + " was updated",
            "success",
            true
          );
        }
      }
    );
  }

  function addClassroom() {
    viewContext.modal.show(
      {
        title: "Create School",
        buttonText: "Save",
        url: "/api/classroom/new",
        method: "POST",

        form: {
          school_source_id: {
            type: "hidden",
            value: params.id,
          },
          account_id: {
            type: "hidden",
            value: account_id,
          },

          name: {
            label: "Name",
            type: "text",
            required: true,
          },
          subject: {
            label: "Subject",
            type: "text",
            required: true,
          },
          periods: {
            label: "Period",
            type: "text",
            required: true,
          },
          grades: {
            label: "Grades",
            type: "text",
            required: true,
          },
        },
      },
      (res) => {
        if (res && res.data && res.data.data) {
          navigate(
            `/organization/school/${params.id}/classroom/${res.data.data.id}`
          );
        }
      }
    );
  }

  function addSchoolAdmin() {
    viewContext.modal.show(
      {
        title: "Add School Admin",
        form: {
          email: {
            label: "Email",
            type: "text",
            required: true,
          },
          permission: {
            label: "Permission",
            value: "school_admin",
            type: "hidden",
          },
        },
        buttonText: "Send Invite",
        text: "To invite more than one user, seperate the emails with a comma",
        url: "/api/invite",
        method: "POST",
      },
      (form, res) => {
        // add the invited user to the
        if (res && res.length) {
          const state = [...memberAdmin];

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                name: "",
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || "school_admin",
                status: "Invited",
                actions: {
                  invite: resendInvite,
                  delete: deleteInvite,
                },
              });
            }
          });

          Event("invited_user");
          setMemberAdmin(state);
        }
      }
    );
  }

  function editUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Update User",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: data.name,
            errorMessage: "Please enter a name",
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
          permission: {
            label: "Permission",
            type: data.permission === "school_admin" ? null : "select",
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "school_admin" &&
                x.value !== "teacher" &&
                x.value !== "student"
            ),
            default: data.permission,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          data.name + " was updated",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete District Admin",
        form: {},
        buttonText: "Delete Admin",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.name + " was deleted",
          "success",
          true
        );
        callback();
      }
    );
  }

  function deleteInvite(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete Invite",
        form: {},
        buttonText: "Delete Invite",
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/invite/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          `${data.email}'s invite was deleted`,
          "success",
          true
        );
        callback();
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      viewContext.notification.show(
        `Invite re-sent to ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      viewContext.handleError(err);
    }
  }

  async function deleteSchool() {
    viewContext.modal.show(
      {
        title: "Delete School",
        form: {},
        buttonText: "Delete School",
        text: `Are you sure you want to delete ${school.data.name}?`,
        url: `/api/organization/delete/${params.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          `${school.data.name} was deleted`,
          "success",
          true
        );
        navigate("/organization/district");
      }
    );
  }

  return (
    <>
      <Header />

      <header className="content-header">
        <h1 onClick={editSchool} style={{ cursor: "pointer" }}>
          School: <span>{schoolName}</span>
        </h1>
        <Button
          action={deleteSchool}
          text="Delete School"
          icon="trash"
          color="red"
          position="right"
        />
      </header>

      <Button
        action={addSchoolAdmin}
        text="Add School Admin"
        icon="plus"
        outline
        color="blue"
      />

      <OrganizationCard loading={memberAdmin?.loading} title="School Admins">
        <Table
          data={memberAdmin}
          show={["email", "name", "status"]}
          badge={{
            col: "status",
            color: "blue",
            condition: [
              { value: "verified", color: "green" },
              { value: "registered", color: "blue" },
              { value: "invited", color: "orange" },
            ],
          }}
          actions={{
            edit: editUser,
            delete: deleteUser,
            email: true,
          }}
        />
      </OrganizationCard>
      <br />
      <Button
        action={addClassroom}
        text="Add Classroom"
        icon="plus"
        outline
        color="blue"
      />
      <OrganizationCard title={"Classes " + classes?.data?.length}>
        <OrganizationTable
          search
          loading={classes?.loading}
          data={classes?.data}
          show={classesList}
          hide={["source_id"]}
          link={{
            url: "/organization/school/" + school.data?.source_id,
            classroom_id: "source_id",
          }}
        />
      </OrganizationCard>
    </>
  );
}
