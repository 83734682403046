/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext, useState, useEffect } from "react";

import {
  AuthContext,
  Animate,
  Button,
  OrganizationCard,
  useAPI,
  Loader,
} from "components/lib";

import Axios from "axios";

export function Index(props) {
  const context = useContext(AuthContext);
  const userContext = context.user;
  const userData = useAPI("/api/user");
  console.log("user", userData);

  const [state, setState] = useState([{ loading: true }]);

  // currently only accounts with a district can manage orgs
  // this is a temporty solution until we have account types that we can use to
  // determine if an account can manage orgs
  const [canManageOrg, setCanManageOrg] = useState(false);

  let district_id = useAPI("/api/account/district");

  useEffect(() => {
    // get the school_id
    if (userContext.permission === "school_admin") {
      Axios.get(
        "/api/organization/school/id/" + userContext.school_source_id
      ).then((response) => {
        setState({ school_id: response.data.data.id });
      });
    }
  }, [userContext]);

  useEffect(() => {
    if (district_id.data) {
      setCanManageOrg(true);
    }
  }, [district_id]);

  function friendlyPermissionName(str) {
    if (!str) return;
    // Split the string into an array on underscore
    let strArr = str.split("_");

    // Map over the array and capitalize each word
    let capitalizedArr = strArr.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // join the words back into a single string with no underscore
    let result = capitalizedArr.join(" ");

    return result;
  }

  return (
    <>
      <header className="content-header">
        <h1>
          <span> Organization</span>
        </h1>
      </header>
      <Animate>
        {!context.permission?.student && canManageOrg && (
          <OrganizationCard className="account" title="Organization Management">
            {context.permission?.account_admin && (
              <cite className="badge">
                {friendlyPermissionName(userContext?.permission)}
              </cite>
            )}
            <p>
              Organization management enables you to oversee various aspects of
              your account and organizations. Depending on your permissions, you
              can view and edit settings for the District, School, and
              Classroom. Additionally, you have the ability to access Teacher
              and Student accounts.
            </p>
            {
              context.permission?.district_admin ? ( // Conditional render based on premissions
                <>
                  <Button
                    url="/organization/district"
                    text="Edit"
                    className={Style.link}
                    icon="users"
                  />
                  <Button
                    url={"/organization/content/district/"}
                    text={"Content"}
                    className={Style.link}
                    icon="check-square"
                  />

                  <Button
                    url={"/organization/words/district/"}
                    text={"Blocked Words"}
                    className={Style.link}
                    icon="slash"
                  />
                </>
              ) : context.permission?.school_admin ? (
                <span>
                  <Button
                    url={"/organization/school/" + state.school_id}
                    text={"Edit"}
                    className={Style.link}
                    icon="users"
                  />

                  <Button
                    url={"/organization/content/school/" + state.school_id}
                    text={"Content"}
                    className={Style.link}
                    icon="check-square"
                  />

                  <Button
                    url={"/organization/words/school/" + state.school_id}
                    text={"Blocked Words"}
                    className={Style.link}
                    icon="slash"
                  />
                </span>
              ) : context.permission?.teacher ? (
                <Button
                  url="/organization/teacher/school"
                  text={"Manage"}
                  className={Style.link}
                  icon="users"
                />
              ) : null // null or any default case when none of the conditions are true.
            }
          </OrganizationCard>
        )}
      </Animate>
    </>
  );
}

const Style = {
  subtitle: "font-bold mt-4",
  link: "text-blue-500 font-semibold mt-3 inline-block",
};
