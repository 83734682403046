import { Index } from "views/organization";
import { District } from "views/organization/district";
import { School } from "views/organization/school";
import { Classroom } from "views/organization/classroom";
import { Teacher } from "views/organization/teacher";
import { TeacherSchool } from "views/organization/teacher-school";
import { Student } from "views/organization/student";
import { History } from "views/organization/history";
import { Manage } from "views/account/manage";
import { Content } from "views/organization/content";
import { Words } from "views/organization/words";

const Routes = [
  {
    path: "/organization",
    view: Index,
    layout: "app",
    permission: "district_admin",
    title: "Organization Management",
  },
  {
    path: "/organization/district",
    view: District,
    layout: "app",
    permission: "district_admin",
    title: "District",
  },
  {
    path: "/organization/school/:id",
    view: School,
    layout: "app",
    permission: "school_admin",
    title: "School",
  },
  {
    path: "/organization/school/:school_id/classroom/:class_id",
    view: Classroom,
    layout: "app",
    permission: "teacher",
    title: "Classroom",
  },
  {
    path: "/organization/teacher/school",
    view: TeacherSchool,
    layout: "app",
    permission: "teacher",
    title: "Teacher School",
  },

  {
    path: "/organization/teacher/:id",
    view: Teacher,
    layout: "app",
    permission: "teacher",
    title: "Teacher",
  },
  {
    path: "/organization/student/:id",
    view: Student,
    layout: "app",
    permission: "teacher",
    title: "Student",
  },

  {
    path: "/organization/history/:id",
    view: History,
    layout: "app",
    permission: "teacher",
    title: "History",
  },

  {
    path: "/account/manage",
    view: Manage,
    layout: "app",
    permission: "account_admin",
    title: "School Management",
  },
  {
    path: "/organization/content/district",
    view: Content,
    layout: "app",
    permission: "district_admin",
    title: "Content Management",
  },

  {
    path: "/organization/content/school/:id",
    view: Content,
    layout: "app",
    permission: "school_admin",
    title: "Content Management",
  },
  {
    path: "/organization/words/district",
    view: Words,
    layout: "app",
    permission: "district_admin",
    title: "Word Management",
  },
  {
    path: "/organization/words/school/:id",
    view: Words,
    layout: "app",
    permission: "school_admin",
    title: "Word Management",
  },
];

export default Routes;
